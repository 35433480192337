.nota-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;
}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.nota-container-text {
	margin-bottom: 0rem;
	width: 100%;
	position: relative;
}

.nota-title {
	font-size: 1.25rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
	width: 100%;
}

.nota-summary {
	display: none;
	font-size: 1rem;
	line-height: 1.375;
	color: var(--gray-text);
}

.background{
	background: #fff000;
	padding: 20px 10px;
}

.nota-autor {
	margin-top: 0.2rem;
	color: var(--medium-black);
    font-weight: 300;
    font-size: .8rem;
}

.nota-autor a{
	font-weight: 700;
}

.display-none {
	display: none;
}

.blue-txt { 
	color: var(--sky-blue);
	font-size: 1.25rem;
}

.nota-container-text h2 {
	font-size: 1.25rem;
    padding: .25rem 0 .75rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
}

.patrocinado{
	display: flex;
    align-items: center;
    position: absolute;
	-moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: .5rem;
    background-color: #000000b8;    
    color: var(--white);
    padding: .5rem .5rem;
    border-radius: 4px;
    font-size: .55rem;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 400;
}

.img-container {
	height: 200px;
	margin-bottom: 5px;
	width: 300px;
    overflow: hidden;
	}


@media (max-width: 768px) {
	.img-container{

		width: 100%;
		height: auto;
	}
	.nota-container-text {
		margin-bottom: 0rem;
	}
	h2 .nota-title {
	font-size: 1.25rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
	}
	.nota-summary {
		margin: 0rem 0;
	}
	.nota-autor {
		margin-top: 0rem;
	}
	
}
